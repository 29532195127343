.chart-settings-dropdown{

    &_item{
        font-size: 12px;
        padding:2px 10px 2px 20px;
        white-space: nowrap;
        gap:10px;
    }

}
.highcharts-plot-line-label {
    color: red;
    font-size: 12px !important;
    font-weight: 700;
    transform: rotate(1deg);
}
.color-indicator{
    width: 7px;
    height:7px;
    display:inline-block;
    border-radius:50px;
    margin-right:10px 
}