@import '../../styles/variables/colors';

.box-icon{
    width: 65px;
    font-size: 65px;
    color:#9cd094;
    background:$green-gradient-icn;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
}
.classification-box {
        max-width: 200px;
        height: 200px;
        background-color: #fff;
        margin-left:10px;
        margin-right: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
        margin-bottom: 10px;
        
        &:hover{
            box-shadow:0 .5rem 1rem rgba(0,0,0,.45) !important; 
        }

        h6{
            min-height: 50px;
        }

        &-icon{
            flex-grow: 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

}

.kpi-unit-listing{
    background-color: #fff;
    max-width: 640px;
    margin: 0 auto;
    margin-top: 30px;

    &--header{
        .close-icon{
            width: 24px;
            margin-left:15px
        }
    }

    &--main{
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        i{
            font-size:32px;
        }
    }

}

.customTooltip{
    // background-color: #9cd094;
    line-height: 10px;
}
