.daz-icon__dropdown{
    .dropdown-menu{
        min-width: 7rem;
        background: #F8F8F8;
        border: 1px solid rgba(123, 123, 123, 0.5);
        border-radius: 5px;

    }
    .dropdown-item{
        padding-left: 0;
        padding-right: 0;
    }
    button{
        background-color: transparent !important;
        color: inherit !important;
        border: none  !important;
        outline: none !important;
        box-shadow: none !important;
        padding: .25rem 1.5rem;
       
        &:after{
            display: none;
        }
    }

    &-settings{
        position: relative;
        font-size: 23px;
        top:-2px;
       
        &.green{
            color:#9cd094;
            background:$green-gradient-icn;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            overflow: hidden;
        }
    }

} 