.daz-main-wrp{
    display: flex;
  
}
.daz-main-content{
    // flex-grow: 1;
    background-color: $grey-bg;
    margin:0 10px 10px;
    padding: 20px 30px 0;
    width: calc(100vw - 135px);
    position: relative;
   // .four-layout{
        .each-col{
            position: relative;
        }
   // }
   
}

.process-page .top-functions, .home-chart-layout .top-functions{
    height: 28px;
}

.home-chart-layout{
    background-color: $white-bg;
    padding: 10px;
    margin-bottom: 20px;
    min-height: 330px;
    .top-functions{

        &.comparison-view-top{
            position: relative;
        }
        .comparison-fitler-buttons{
            position: absolute;
            top:0px;
            z-index: 1;

           

            .graph-filter-btn{
                width: 21px;
                height: 21px;
                padding: 0px;
                min-width: auto;

            }
        }
    }

    i{
        color: $dark-grey;
        cursor: pointer;
    }
    

    .daz-icon__dropdown > button{
        padding: 10px 5px 0;
    }
}


.custom-legend{
    right: 25px;
    bottom: 25px;
    font-size: $font-xsmall;
    color:rgba(99, 99, 99, 0.8);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.incompletePeriod:before{
        content: "";
        width: 10px;
        height: 10px;
        background-color:#C4C4C4;
        border-radius: 50%;
        display: inline;
    }

    &.peakload:before{
        width: 10px;
        height: 2px;
        border-top:  2px dotted $chart-blue;
    }        

    &:before{
        content: "";
        margin-right: 5px;
    }
}


.four-layout .highcharts-wrp{
    height: 260px;
    position: relative;
  
}
.two-layout .highcharts-wrp{
    height: 380px;
    position: relative;
}

.chart-layout-expanded .home-page .highcharts-wrp{
    height: 580px !important;
}
.chart-layout-expanded .highcharts-wrp,.single-chart-page .highcharts-wrp{
    height: 520px !important;
}
.process-page .highcharts-wrp{
    height: 490px;
  
}

.main-process-intantaneous-power .home-page .highcharts-wrp,.main-process-intantaneous-power .process-level-page .highcharts-wrp, .main-process-intantaneous-power .machine-level-page.four-layout .highcharts-wrp{
  height: 240px;
}
.main-process-intantaneous-power .two-layout.machine-level-page .highcharts-wrp{
 height: 380px;
}

.chart-layout-expanded > div{
    height: 100%;
}

.graph-filter-btn{
    background-color: $grey-bg;
    margin-right: 4px;
    padding: 3px 7px;
    border: none;
    font-size: 11px;
    border-radius: 3px;   
    color: rgb(99, 99, 99);
    min-width: 40px;
    &.graph-filter-active{
        border: 1px solid #7D7D7D;

    }
  
}

.process-nav,.process-nav-dropdown{
    display: flex;
    align-items: center;
    justify-content: center;
    color:$dark2-grey;

    i{
        width: 24px;
        margin: 0 10px;
        cursor: pointer;
        margin-top: 4px;
    }
    h4{
        color: $dropdown-txt-grey;
        font-weight: $font-light;
    }
}
.process-nav-dropdown{
    position: relative;
    
    .dropdown{
        right: 0;
    }
    a{
        color: $black-txt;
        display: block;
    }

    .bt-dropdown-menu{
        max-height: 350px;
        overflow-y: auto;

    }
}
.inner-breadcrumb{
    font-weight: $font-light;

    i{
        width: 24px;
        margin-right: 10px;
        color: $dropdown-arrow-grey;
    }
    a{
        display: inline-flex;
        align-items: center;
        color: $dark3-grey;

    }
}

.chart-bg{
    background-color: #fff;
    padding: 10px;

    i{
        color: $dark-grey;

    }
    .top-functions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .daz-icon__dropdown > button {
            padding: 10px 5px 0;
        }
        i{
            cursor: pointer;
        }
    }
}

.white-chart-bg{
    background-color: #fff;
    width: 100%;
    height: 100%;
}
.equipment-filter{
    background: #FFFFFF;
    border: 1px solid #9B9B9B;
    border-radius: 3px;
    color: #636363; 
    cursor: pointer;
    font-weight: 400;
    padding: 0px 8px;
    font-size: 12px;
}
.equipment-filter.active{
    background: #7D7D7D;
    border: 1px solid #7D7D7D;
    color: #fff;
}
.layout-heading{
    color: $dropdown-txt-grey;
    font-weight: $font-light;
    margin-bottom: 20px;
}


.totalenergyuse-comparison{
    .highcharts-legend-item{
        pointer-events: none;
    }
}
.zone-back,.inner-drilldown-back{
    position: absolute;
    z-index: 1;
    /* left: auto; */
    right: 0;
    padding: 3px 10px;
    font-size: 13px;
    top: 8px;
}
.calendar-icon{
    overflow: hidden;
    width: 21px;
    margin-right: 5px;
}
.tooltip-bolt-icon{
    color: #FCC01E;
    fill: #FCC01E;
    font-size: 18px;
}

.tooltip-eco-icon{
    color: $green;
    fill: $green;
    font-size: 16px;
}
.dash-right-border{
    border-left: 1px dashed #242D5C;
 
    padding-left: 10px;
}