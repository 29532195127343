.sidebar-slider.excel-download{
    .react-datepicker-wrapper input{
        background-color: #FFF;
        padding: 9px 10px;
        border: none;
        font-size: 16px;
        border-radius: 3px;
        color: #636363;
        width: auto;
        text-align: center;
    }
    
}

.excel-download-toast{
    z-index: 1;
}

.toast{
    background-color: #fff;
}
.react-datepicker-wrapper input:disabled{
    opacity: 0.5;
}