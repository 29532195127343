.lineTab{
    border-bottom: none;
    margin-bottom: 5px;
    .nav-item{
        .nav-link{
            padding: 0;
            margin-right: 10px;
            border-bottom: none;
            position: relative;
            color:$heading-grey;
            border: none;
            z-index: 1;
            
           &.active{
            background-color: unset;
           } 

           &.active,&:hover{
            // border-bottom:solid 2px $dark-green;
            // border-radius: 0;
            // text-decoration: underline solid $dark-green 3px;
            // text-decoration-skip-ink: none;
            // border-bottom: none;

             &::before {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                height: 4px;
                border-radius: 2px;
                background: $green-gradient-icn;
                z-index: -1;
              }
              
           }
        }
    }
}