.btn:focus{
    outline: none;
    box-shadow: none;
}

.btn-grad {background-image: $green-gradient-btn}
.btn-grad {
    margin: 10px;
    min-width: 80px;
    border:none;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-grey{
    background-color: $light-grey;
}
.btn-grey:hover{
    background-color: $hover-grey;
}
.btn-darkgrey{
    background-color: #7D7D7DB2;
    color: #fff;
    border:none;
}
.btn-darkgrey:hover{
    color:#fff
}
.btn-secondary{
    border: none;
}
.btn-grad:hover {
background-position: right center; /* change the direction of the change here */
color: #fff;
text-decoration: none;
}
    
.bt-dropdown-nav{
    .dropdown-item{
        padding: 0;
        a{
            padding: .25rem 1.5rem;
            
        }
        a.bt-dropdown--active,a.bt-dropdown--active:focus,a.bt-dropdown--active:active{
            color: #fff;
            background-color: $hover-blue;
        }
    }
}