.filter_by_date{
    
}
.filter_by_date--inputs{
    input{
        border: 1px solid $dark-grey;
        font-size: 12px;
        padding: 2px 10px;
        border-radius:3px ;
    }
}
.daz-daterangepicker{
    input{
        width: 160px;
    }
    .react-datepicker__close-icon::after{
        position: relative;
        left: 3px;
        height: 13px;
        width: 13px;
        padding: 0;
        background-color: #9B9B9B;
    }
}