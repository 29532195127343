.sidebar-slider{

    &.main-layout-slider{
        left: -10px;
    }

    .graph-filter-btn {
        font-size: 13px;
        padding: 8px 7px;
        margin-right: 20px;
    }

}