.daz-listing{
    color: #7D7D7D;
    font-size: $font-med;
    line-height: 24px;

    li{
        border-bottom: 1px solid rgba(125, 125, 125, 0.5);
        padding-top: 6px;
        padding-bottom: 6px;
        text-align: left;
    }
}

.set-alert{

    .react-datepicker-wrapper input{
    background-color: #EEEEEE;
    padding: 9px 7px;
    border: none;
    font-size: 16px;
    border-radius: 3px;
    color: #636363;
    width: 100%;

        &[disabled]{
            opacity: 0.5;
        }
    }

   .timepicker-only {
         background-color:transparent;
         border: none; 
         .react-datepicker__header{
             display: none;
         }
        .react-datepicker__month-container{
            opacity:0;
            z-index: -999;
            pointer-events: none;
        } 
        .react-datepicker__navigation{
            display: none
        }
        .react-datepicker__time-container {
            border: 1px solid #aeaeae;
        }
        .react-datepicker__triangle::after,.react-datepicker__triangle::before{
            display: none;
        }
        .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
            margin-right: 2px;
        }
    }
}

.active-alert{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &_none{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
    
    &_name{
        width:30% ;
        word-break: break-word;
        padding-right: 5px;
    }
    &_limit{
        width: 50%;
    }
    i{cursor: pointer;}

    &_expired{
        background-color: #fff3cd !important;
        border: 1px solid #664d03;
        color:#664d03;
        display: block;
        font-weight: $font-light ;
        max-width: 50px;
        margin-left: auto;
    }

    &_change{
        width: 20%;
        text-align: right;
        i{
            font-size: 18px;
        }
    }

  
}
.alert-slider
{
    p{
        line-height: 21px;
    }
    .daz-listing{
        overflow-y: auto;
    }
    .select-source{
        .daz-listing{
            // max-height: 475px;
            padding-right: 15px;
            margin-right: -20px;
            // -webkit-mask-image: linear-gradient(to bottom, black 91%, transparent 100%);
            // mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
            // padding-bottom: 10%;
        }
    }
    .active-alert{
        .daz-listing{
            // max-height: 563px;
            display: flex;
            flex-direction: column;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;
            padding-right: 15px;
            margin-right: -20px;
            // -webkit-mask-image: linear-gradient(to bottom, black 91%, transparent 100%);
            // mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
            // padding-bottom: 10%;
        }
        .weekdays-btn{
            font-size: 9px;
            height: 17px;
            width: 17px;
            padding: 1px;
            cursor: default;
        }
    }

    .weekdays-btn{
        background: #fff;
        border: solid $light2-grey 1px;
        color: $light2-grey ;
        width: 35px;
        height: 30px;
        border-radius: 5px;
        opacity: 0.5;
        line-height: normal;

        &_active{
            background:$dark2-grey ;
            border: solid $dark2-grey 1px;
            color:#fff;
            opacity: 1;
        }
    }


}

.alert-notification{
    .heading-mb{
        margin-bottom: 15px;
       
    }

    .light-heading{
        color: #cdcdcd;
    }
    text-align: left;
    
    
    .react-datepicker-wrapper{
        width: 70px;
        input.timerange{
        display: inline-block;
        background: #fff;
        border: solid $light2-grey 1px;
        color: $light2-grey ;
        text-align: center;
        line-height: normal;
        height: 24px;
        padding: 0px;
        }    
    }
    &_email-listing{
        font-size: 15px;
    }
    &_email{
        width: calc(100% - 50px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}