.daz-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    &.small-dark{
        width: 40px;
        height: 18px;
  
        .slider {
            background-color: $dark2-grey;
        }
        .slider:before {
            width: 12px;
            height: 12px;
            left: 4px;
            bottom: 3px;
        }
        input:checked + .slider {
            background-color: $dark2-grey;
          }
          
        input:focus + .slider {
        box-shadow: 0 0 1px $dark2-grey;
        }

        
      input:checked + .slider:before {
        @include translateX(20px);
        background-color: #44F055;
      }    

    }

    &.small{
      width: 20px;
      height: 10px;
      .slider:before {
        width: 6px;
        height: 6px;
        left: 2px;
        bottom: 2px;
      }

      input:checked + .slider:before {
        @include translateX(9px);
      }
    }

    input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #7B7B7B;
        -webkit-transition: .4s;
        transition: .4s;
            
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: #44F055;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #44F055;
      }

      
      
      input:checked + .slider:before {
        @include translateX(26px);
      }  


      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
  }
  
