@include respond-below(md){
    .daz-sidebar{
        max-width: 70px;
    }
    .daz-main-content{
        width: calc(100vw - 70px);
        padding: 20px 10px 0;
    }
    .sidebar-slider{
        left: 70px;
        width: calc(100vw - 70px);
    
    }

    .daz-sidebar__iconwrap{ 
    
    & i {
            font-size: 30px !important;
        }

    & span{
            font-size: 11px;
        }
    }

    .home-chart-layout .top-functions{
        height: auto;
    }

    .home-chart-layout .top-functions,.home-chart-layout .top-functions .flex-c{
        flex-wrap: wrap;
    }

    .alert-notification_email-listing {
        font-size: 12px;
    }

    .summary-graph__each{
       // width: 150px;
       margin-bottom: 10px;
    }

}
