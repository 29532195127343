
.linkAccordion{

    .accordion-body{
        padding: 0;
    }

    .accordion-item{
        background-color: transparent;
        border: none;
    }
    .accordion-header{
        width: max-content;

        button{
            background: none;
            padding: 0;
            border: none;
            color: $hover-blue ;

            box-shadow: none;
        
            &:not(.collapsed){
            color: $general-txt;

            }

            &:after{
                margin-left: 5px;
                background-size: 15px;
                background-position:center
            }
        }
    }
}