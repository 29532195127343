
input[type=range]::-webkit-slider-runnable-track {
    background: $bar-grey !important;
}
input[type=range]::-ms-track {
    background: $light-grey !important;
}
input[type=range]::-moz-range-track {
    background: $light-grey !important;
}

input[type=range]::-webkit-slider-thumb {
    background: $green-gradient-icn !important;
}
input[type=range]::-ms-thumb{
    background: $green-gradient-btn !important;
}
input[type=range]::-moz-range-thumb{
    background: $green-gradient-btn !important;
}

input[type="range"]:focus::-webkit-slider-thumb  {
    box-shadow: none !important;
}
input[type="range"]:focus::-moz-range-thumb {
    box-shadow: none !important;
}


input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}






/*hide the outline behind the border*/
// input[type=range]:-moz-focusring{
//     outline: 1px solid white;
//     outline-offset: -1px;
// }


