.daz-user-operator{
    .manager-view{
        display: none;
    }
    .operator-view{
        display: block;
    }
    .summary-graph{
        display: none;
    }
    .daz-sidebar_icons{
        display: none;
    }
    .daz-header__dropdownProfile{
        display: block;
    }
    .sidebar-slider{
        left:0
    }
    .daz-main-content{
        width: 100%;

    }
    .daz-header__processwrap{
        display: block;
    }
    .four-layout .highcharts-wrp{
         height: calc((100vh - 200px) / 2);
    }
     .chart-layout-expanded .four-layout .highcharts-wrp, .chart-layout-expanded .two-layout .highcharts-wrp{
        height: calc(100vh - 200px);
    }
    .main-process-intantaneous-power .home-page .highcharts-wrp,.main-process-intantaneous-power .process-level-page .highcharts-wrp, .main-process-intantaneous-power .machine-level-page.four-layout .highcharts-wrp{
        height: calc((100vh - 240px) / 2);
    }
}

.daz-header__processwrap{
 display: none;
}
.operator-view{
    display: none;
}
.base-grey > *{
    color: $heading-grey
}